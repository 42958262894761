// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    firebase: {
        apiKey: 'AIzaSyC8i4SnWmtBAaPAp1hLLDvvg_NSvLLttZk',
        authDomain: 'facerehab-5a98b.firebaseapp.com',
        databaseURL: 'https://facerehab-5a98b-dev.europe-west1.firebasedatabase.app/',
        projectId: 'facerehab-5a98b',
        storageBucket: 'facerehab-5a98b.appspot.com',
        messagingSenderId: '39094410182',
        appId: '1:39094410182:web:5f3e925bddc54a084a30c7',
        measurementId: 'G-1TMSJ2V5Y2'
    },
    hbFunctionUrl: 'http://127.0.0.1:5001/facerehab-5a98b/us-central1/scale',
    rtc: {
        // For the local client.
        client: null,
        // For the local audio and video tracks.
        localAudioTrack: null,
        localVideoTrack: null
    },
    options: {
        // Pass your app ID here.
        appId: '089984d0da464a5b854ee80ebb01aeff',
        // Set the channel name.
        channel: 'test',
        // Pass a token if your project enables the App Certificate.
        token: '006089984d0da464a5b854ee80ebb01aeffIADKbZCmKSPFiYIfHOrBR1Ea4F0vDRUrnW93pMwcL2N/Qgx+f9gAAAAAEAA8TtCod9V+XwEAAQB21X5f'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
