import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    onUserChanged: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor() {
    }

    private _patientUid: string;

    get patientUid(): string {
        return this._patientUid;
    }

    set patientUid(value: string) {
        this._patientUid = value;
    }
}
