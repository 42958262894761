import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(private db: AngularFireDatabase) {
    }

    // General functions

    public createUserDetails(user: firebase.User): void {
        this.db.database.ref('/users').child(user.uid).set({
            group: 'patient',
            email: user.email,
            uid: user.uid,
            verified: user.emailVerified
        });
    }

    public getUserDetails(uid: string): Promise<any> {
        return this.db.database.ref('/users').orderByChild('uid').equalTo(uid).once('value');
    }

    public getUserDetailChanges(uid: string): Observable<any> {
        return this.db.list('/users/' + uid).snapshotChanges();
    }

    // Therapist view functions

    public getPatientList(uid: string): Observable<any> {
        return this.db.list('/therapists/' + uid, ref => ref.orderByValue().equalTo(true)).snapshotChanges();
    }

    // Patient view functions
    public getDiagnosticDataForPatient(uid: string): Observable<any[]> {
        return this.db.list('/patients/' + uid + '/scans/facialExpressions/calendar',
            ref => ref.orderByKey()).snapshotChanges();
    }

    public getTrainingPlanForPatient(uid: string): Observable<any[]> {
        return this.db.list('/patients/' + uid + '/trainingPlan').snapshotChanges();
    }

    public getTrainingData(uid: string, id: string): Observable<any[]> {
        return this.db.list('/patients/' + uid + '/training/', ref => ref.orderByKey().equalTo(id)).snapshotChanges();
    }

    public getRealtimeDataForPatient(uid: string): Observable<any[]> {
        return this.db.list('/patients/' + uid + '/realtime').snapshotChanges();
    }

    public unsubscribePatientFromApp(uid: string): Promise<any> {
        return this.db.database.ref('/users/' + uid).child('dataNode').remove();
    }
}
