import {FuseNavigation} from '@fuse/types';

export const navigationPatient: FuseNavigation[] = [
    {
        id: 'patient',
        title: 'Patient view',
        translate: 'NAV.PATIENT_VIEW',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'diagnostic',
                title: 'Diagnose',
                translate: 'NAV.DIAGNOSTIC',
                type: 'item',
                icon: 'today',
                url: '/pages/diagnostic-view'
            }/*,
            {
                id: 'training',
                title: 'Excercise',
                translate: 'NAV.TRAINING',
                type: 'item',
                icon: 'today',
                url: '/pages/training-view'
            },
            {
                id: 'realtime',
                title: 'Realtime',
                translate: 'NAV.REALTIME',
                type: 'item',
                icon: 'today',
                url: '/pages/realtime-view'
            }*/
        ]
    }
];

export const navigationTherapist: FuseNavigation[] = [
    {
        id: 'patient',
        title: 'Patient view',
        translate: 'NAV.PATIENT_VIEW',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'patient_list',
                title: 'Patient list',
                translate: 'NAV.PATIENT_LIST',
                type: 'item',
                icon: 'today',
                url: '/pages/patient-list'
            }
        ]
    }
];
