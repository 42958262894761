export const locale = {
    lang: 'sk',
    data: {
        'NAV': {
            'PATIENT_VIEW': 'Prehľad pacienta',
            'DIAGNOSTIC': 'Diagnostika',
            'TRAINING': 'Tréningy',
            'REALTIME': 'Sledovanie pacienta',
            'PATIENT_LIST': 'Zoznam pacientov'
        }
    }
};
