export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'PATIENT_VIEW': 'Patient view',
            'DIAGNOSTIC': 'Diagnostic',
            'TRAINING': 'Exercise',
            'REALTIME': 'Realtime',
            'PATIENT_LIST': 'Patient list'
        }
    }
};
