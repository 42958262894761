import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import * as firebase from 'firebase/app';
import {User} from '../models/user';
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {FirebaseService} from './firebase.service';
import {UserGroup} from '../models/therapist/user-group.enum';


@Injectable({
    providedIn: 'root'
})
export class AuthService implements Resolve<any> {
    private user: User = null;
    private therapistRoutes = ['/pages/patient-list', '/pages/diagnostic-view', '/pages/training-view', '/pages/errors/error-500', '/pages/errors/error-404'];
    private patientRoutes = ['/pages/diagnostic-view', '/pages/training-view', '/pages/connect-app', '/pages/errors/error-500', '/pages/errors/error-404'];

    constructor(
        private _router: Router,
        public _firebaseService: FirebaseService,
        public _dataService: DataService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return undefined;
    }

    // Sign up with email/password
    register(email, password): Promise<any> {
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    login(email, password): Promise<any> {
        return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                this.user = null;
                return firebase.auth().signInWithEmailAndPassword(email, password);
            });
    }

    forgotPassword(email): Promise<any> {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    logout(): void {
        firebase.auth().signOut()
            .then(() => {
                this.user = null;
                this._router.navigate(['/auth/login']);
            });
    }

    getIdToken(): Promise<string> {
        return firebase.auth().currentUser.getIdToken(true);
    }

    createUserDetails(user: firebase.User): Promise<User> {
        this._firebaseService.createUserDetails(user);
        return this.loadUserDetails(user.uid);
    }

    loadUserDetails(uid: string): Promise<User> {
        return new Promise((resolve, reject) => {
            if (this.user && this.user.dataNode) {
                resolve(this.user);
            } else {
                this._firebaseService.getUserDetails(uid).then(snapshot => {
                    if (snapshot.val()) {
                        const key = Object.keys(snapshot.val())[0];
                        const userDetails = snapshot.val()[key];

                        if (userDetails) {
                            this.user = new User(uid,
                                userDetails['dataNode'],
                                userDetails['displayName'],
                                userDetails['email'],
                                userDetails['detail'],
                                userDetails['group']);
                        }
                    } else {
                        throw new Error('User not found.');
                    }

                    if (this.user.group === UserGroup.patient) {
                        this._dataService.patientUid = this.user.dataNode;
                    }

                    this._dataService.onUserChanged.next(this.user);
                    resolve(this.user);
                }).catch(reason => reject(reason));
            }
        });
    }

    waitForAppConnect(): Observable<any> {
        return this._firebaseService.getUserDetailChanges(this.user.uid);
    }

    unsubscribeFromApp(): Promise<any> {
        return this._firebaseService.unsubscribePatientFromApp(this.user.uid);
    }

    isUserSet(uid: string): boolean {
        return this.user != null && this.user.uid === uid;
    }

    getUserUid(): string {
        return this.user?.uid;
    }

    getUserGroup(): UserGroup {
        return this.user?.group;
    }

    isUserPatient(): boolean {
        return this.user?.group === UserGroup.patient;
    }

    getAllowedUrlList(): string[] {
        if (this.user.group === UserGroup.therapist) {
            return this.therapistRoutes;
        } else {
            return this.patientRoutes;
        }
    }
}
