import {UserGroup} from './therapist/user-group.enum';

export class User {
    public group: UserGroup;

    public constructor(public uid: string,
                       public dataNode: string,
                       public userName: string,
                       public email: string,
                       public detail: string,
                       group: string) {
        this.group = group === UserGroup.therapist ? UserGroup.therapist : UserGroup.patient;
    }
}
